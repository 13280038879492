body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  padding-top: 60px;
  background-color: black;
  color: #d7d7d7;
}

.navbar-brand a {
  color: white;
}

.pwm2 {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .row {
    margin-right: 15px;
  }
}
